import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  notification,
  Row,
  Space,
} from "antd";
import axios from "axios";
import { API } from "../utils/Constants";
import React, { useMemo } from "react";

const Context = React.createContext({
  name: "Default",
});

const Login = () => {
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement, description) => {
    api.warning({
      message: `We are sorry!`,
      description: (
        <Context.Consumer>{({ name }) => description}</Context.Consumer>
      ),
      placement,
    });
  };
  const contextValue = useMemo(
    () => ({
      name: "Ant Design",
    }),
    []
  );
  const handleSubmit = (values) => {
    axios
      .post(API + "/login", {
        username: values.username,
        password: values.password,
      })
      .then((response) => {
        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem("username", response.data.username);
        localStorage.setItem("user_id", response.data.user_id);
        window.location.href = "/search";
      })
      .catch((error) => {
        openNotification("topRight", error.response.data.detail);
      });
  };

  return (
    <>
      <Context.Provider value={contextValue}>
        {contextHolder}
      </Context.Provider>

      <Row justify="center">
        <Col xs={22} sm={18} md={14} lg={10}>
          <Card
            title={
              <Flex justify="center">
                <h2>Log In</h2>
              </Flex>
            }
          >
            <Form name="login" onFinish={handleSubmit}>
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input username.",
                  },
                ]}
              >
                <Input placeholder="Username" style={{ borderRadius: 0 }} />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input password.",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  style={{ borderRadius: 0 }}
                />
              </Form.Item>
              <Form.Item>
                <Row justify="space-between" align="middle">
                  <Col xs={24} sm={8}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ borderRadius: 0 }}
                      block
                    >
                      Log In
                    </Button>
                  </Col>
                  <Col>
                    <Space>
                      New to Socialinfo?
                      <a href="/signup">Create an account</a>
                    </Space>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Login;
