import { Navigate, useLocation } from "react-router-dom"

export const fetchToken = () => {
    return localStorage.getItem('access_token');
}

export function RequireToken({children}) {
    let auth = fetchToken();
    let location = useLocation();

    if (!auth) {
        return <Navigate to='/' state={{ from: location }} />
    }

    return children;
}
