import {
  Button,
  DatePicker,
  Row,
  Col,
  Space,
  Divider,
  Radio,
  Table,
  Form,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import axios from "axios";
import { API } from "../utils/Constants";
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

function Search() {
  const [scraper, setScraper] = useState("sozialinfo");
  const [dateOption, setDateOption] = useState(1);
  const [optionOneDisabled, setOptionOneDisabled] = useState(false);
  const [optionTwoDisabled, setOptionTwoDisabled] = useState(true);
  const [optionThreeDisabled, setOptionThreeDisabled] = useState(true);
  const [orders, setOrders] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  let day = new Date();
  const currentDate = `${day.getFullYear()}-${String(
    day.getMonth() + 1
  ).padStart(2, "0")}-${String(day.getDate()).padStart(2, "0")}`;

  day.setDate(day.getDate() - 2);
  const twoDaysBefore = `${day.getFullYear()}-${String(
    day.getMonth() + 1
  ).padStart(2, "0")}-${String(day.getDate()).padStart(2, "0")}`;

  day.setDate(day.getDate() - 28);
  const thirtyDaysBefore = `${day.getFullYear()}-${String(
    day.getMonth() + 1
  ).padStart(2, "0")}-${String(day.getDate()).padStart(2, "0")}`;

  const dateFormat = "YYYY-MM-DD";

  const handleDateOptionSelect = (e) => {
    const option = e.target.value;

    setDateOption(option);

    switch (option) {
      case 1:
        setOptionOneDisabled(false);
        setOptionTwoDisabled(true);
        setOptionThreeDisabled(true);
        break;
      case 2:
        setOptionOneDisabled(true);
        setOptionTwoDisabled(false);
        setOptionThreeDisabled(true);
        break;
      case 3:
        setOptionOneDisabled(true);
        setOptionTwoDisabled(true);
        setOptionThreeDisabled(false);
        break;
      default:
        break;
    }
  };

  const fetchData = () => {
    axios
      .get(API + "/orders/" + localStorage.getItem("username"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        params: {
          'page': tableParams.pagination.current,
          'size': tableParams.pagination.pageSize,
          'scraper': scraper,
        },
      })
      .then((response) => {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: response.data.total,
          },
        });

        setOrders(
          response.data.items.map((item) => {
            return {
              ...item,
              finished: item.finished ? (item.error ? <strong style={{color: 'red'}}>Failed</strong> : <strong style={{color: 'green'}}>Completed</strong>) : "Processing...",
              download: (
                <Space>
                  <Button
                    type="primary"
                    onClick={() => handleDownload(item.id)}
                    disabled={item.error ? item.error : !item.finished}
                  >
                    Download
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => handleDelete(item.id)}
                    disabled={!item.finished}
                    danger
                  >
                    Delete
                  </Button>
                </Space>
              ),
              key: item.id,
            };
          })
        );
      })
      .catch((error) => {
        console.log(error.response.data.detail);
      });
  };

  const newOrder = (startDate, endDate) => {
    axios
      .post(
        API + "/order",
        {
          start_date: startDate,
          end_date: endDate,
          user_id: localStorage.getItem("user_id"),
          url: "",
          scraper: scraper,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      .then((response) => {
        fetchData();
      })
      .catch((error) => {
        console.log(error.response.data.detail);
      });
  };

  useEffect(() => {
    fetchData();
    //Implementing the setInterval method
    const interval = setInterval(() => {
      fetchData();
    }, 5000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [tableParams.pagination.current, scraper]);

  const handleSubmit = (values) => {
    let startDate;
    let endDate;

    if (values.scraper === "sozialinfo") {
      switch (dateOption) {
        case 1:
          startDate = values.dateRangeOne[0].format("YYYY-MM-DD");
          endDate = values.dateRangeOne[1].format("YYYY-MM-DD");
          break;
        case 2:
          startDate = values.dateRangeTwo[0].format("YYYY-MM-DD");
          endDate = values.dateRangeTwo[1].format("YYYY-MM-DD");
          break;
        case 3:
          startDate = values.dateRangeThree[0].format("YYYY-MM-DD");
          endDate = values.dateRangeThree[1].format("YYYY-MM-DD");
          break;
        default:
          break;
      }
    } else {
      startDate = currentDate;
      endDate = currentDate;
    }

    newOrder(startDate, endDate);
  };

  const handleTableChange = (pagination) => {
    setTableParams({ pagination });
  };

  const handleDownload = (order_id) => {
    axios({
      url: API + "/download/" + order_id,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "jobs.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  const handleDelete = (order_id) => {
    axios({
      url: API + "/delete/" + order_id,
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    }).then((response => {
      fetchData();
    }))
  }

  const handleScraperChange = (value) => {
    setScraper(value);
  };

  const columns = [
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Progress",
      dataIndex: "finished",
      key: "finished",
    },
    {
      title: "",
      dataIndex: "download",
      key: "download",
    },
  ];

  return (
    <div style={{ margin: 10 }}>
      <Row>
        <Col span={6}>
          <Form
            name="search"
            onFinish={handleSubmit}
            initialValues={{
              scraper: "sozialinfo",
              dateRangeTwo: [
                dayjs(twoDaysBefore, dateFormat),
                dayjs(currentDate, dateFormat),
              ],
              dateRangeThree: [
                dayjs(thirtyDaysBefore, dateFormat),
                dayjs(currentDate, dateFormat),
              ],
            }}
          >
            <Space direction="vertical" style={{width: '100%', paddingRight: 20}}>
              <Divider
                orientation="left"
                orientationMargin={0}
                style={{ marginTop: 0 }}
              >
                Select Scraper
              </Divider>
              <Form.Item name="scraper">
                <Select
                  options={[
                    {
                      value: "sozialinfo",
                      label: "Sozialinfo.ch",
                    },
                    {
                      value: "heiminfo",
                      label: "Heiminfo.ch",
                    },
                    {
                      value: "workswiss",
                      label: "Work.swiss"
                    },
                    {
                      value: "sozjobs",
                      label: "Sozjobs.ch"
                    },
                    {
                      value: "publicjobs",
                      label: "Publicjobs.ch"
                    },
                    {
                      value: "jobs",
                      label: "Jobs.ch"
                    },
                    {
                      value: "gemeindestellen",
                      label: "Gemeindestellen.ch"
                    },
                  ]}
                  onChange={handleScraperChange}
                />
              </Form.Item>
              {(scraper === "sozialinfo") && (
                <Radio.Group
                  onChange={handleDateOptionSelect}
                  value={dateOption}
                  style={{width: '100%'}}
                >
                  {/* Date Range Selection */}
                  <Divider
                    orientation="left"
                    orientationMargin={0}
                    style={{ marginTop: 0 }}
                  >
                    <Radio value={1}>Range Selection</Radio>
                  </Divider>
                  <Form.Item
                    name="dateRangeOne"
                    rules={[
                      {
                        required: !optionOneDisabled,
                        message: "Please select date range.",
                      },
                    ]}
                  >
                    <RangePicker
                      style={{ borderRadius: 0, width: '100%' }}
                      maxDate={dayjs(currentDate, dateFormat)}
                      disabled={optionOneDisabled}
                      allowEmpty
                    />
                  </Form.Item>

                  {/* 2 Last Days */}
                  <Divider orientation="left" orientationMargin={0}>
                    <Radio value={2}>2 Last Days</Radio>
                  </Divider>
                  <Form.Item name="dateRangeTwo">
                    <RangePicker
                      style={{ borderRadius: 0, width: '100%' }}
                      minDate={dayjs(twoDaysBefore, dateFormat)}
                      maxDate={dayjs(currentDate, dateFormat)}
                      disabled={optionTwoDisabled}
                      allowClear={false}
                    />
                  </Form.Item>

                  {/* Past 30 Days */}
                  <Divider orientation="left" orientationMargin={0}>
                    <Radio value={3}>Past 30 Days</Radio>
                  </Divider>
                  <Form.Item name="dateRangeThree">
                    <RangePicker
                      style={{ borderRadius: 0, width: '100%' }}
                      minDate={dayjs(thirtyDaysBefore, dateFormat)}
                      maxDate={dayjs(currentDate, dateFormat)}
                      disabled={optionThreeDisabled}
                      allowClear={false}
                    />
                  </Form.Item>
                </Radio.Group>
              )}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ borderRadius: 0 }}
                  size="large"
                  block
                >
                  Submit
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </Col>
        <Col span={18}>
          <Table
            columns={columns}
            dataSource={orders}
            pagination={tableParams.pagination}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Search;
