import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  notification,
  Row,
  Space,
} from "antd";
import axios from "axios";
import { API } from "../utils/Constants";
import React, { useMemo } from "react";

const Context = React.createContext({
  name: "Default",
});

const Register = () => {
  const [api, contextHolder] = notification.useNotification();
  const openWarningNotification = (placement, description) => {
    api.warning({
      message: `We are sorry!`,
      description: (
        <Context.Consumer>{({ name }) => description}</Context.Consumer>
      ),
      placement,
    });
  };
  const openNotification = (placement, description) => {
    api.info({
      message: `We are happy!`,
      description: (
        <Context.Consumer>{({ name }) => description}</Context.Consumer>
      ),
      placement,
    });
  };
  const contextValue = useMemo(
    () => ({
      name: "Ant Design",
    }),
    []
  );

  const handleSubmit = (values) => {
    axios
      .post(API + "/signup", {
        username: values.username,
        email: values.email,
        password: values.password,
      })
      .then((response) => {
        openNotification("topRight", `User ${response.data.username} has been created!`);
        window.location.href = "/login";
      })
      .catch((error) => {
        openWarningNotification("topRight", error.response.data.detail);
      });
  };

  return (
    <>
      <Context.Provider value={contextValue}>{contextHolder}</Context.Provider>

      <Row justify="center">
        <Col xs={22} sm={18} md={14} lg={10}>
          <Card
            title={
              <Flex justify="center">
                <h2>Sign Up</h2>
              </Flex>
            }
          >
            <Form name="signup" onFinish={handleSubmit}>
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input username.",
                  },
                ]}
              >
                <Input placeholder="Username" style={{ borderRadius: 0 }} />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input email.",
                    type: "email",
                  },
                ]}
              >
                <Input placeholder="Email" style={{ borderRadius: 0 }} />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input password.",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  style={{ borderRadius: 0 }}
                />
              </Form.Item>
              <Form.Item>
                <Row justify="space-between" align="middle">
                  <Col xs={24} sm={8}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ borderRadius: 0 }}
                      block
                    >
                      Sign Up
                    </Button>
                  </Col>
                  <Col>
                    <Space>
                      Already have an account?
                      <a href="/login">Go to Log In</a>
                    </Space>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Register;
